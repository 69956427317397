import type { DraftAccountFormData } from '@spa/scenes/Auth/shared/services/authState/AuthState.types'
import { usePostRefreshToken } from '@spa/scenes/Auth/shared/services/authState/hooks/usePostRefreshToken'
import { useEffect, useState } from 'react'

export const useLoadDraftAccountData = () => {
  const [draftData, setDraftData] = useState<DraftAccountFormData | null>(null)
  const refreshTokenMutation = usePostRefreshToken()

  useEffect(() => {
    refreshTokenMutation.process({ forceRefresh: true }).then(response => {
      if (response?.info.draft_account_form_data) {
        setDraftData(response.info.draft_account_form_data)
      }
    })
  }, [])

  return {
    draftData: draftData as DraftAccountFormData,
    isLoading: !draftData
  }
}
