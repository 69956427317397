import { httpServices } from '../../apis'
import type { QueryFnParams } from '../../types'

import type {
  GetSignupCheckExistingEmailParams,
  GetSignupCheckExistingEmailResponse,
  GetUserConfirmationQueryParams,
  GetUserInvitationQueryParams,
  GetUserInvitationResponse,
  PostCheckExistingEmailPayload,
  PostCheckExistingEmailResponse,
  PostCreateUserPayload,
  PostCreateUserResponse,
  PostImpersonateUserResponse,
  PostOauthPasswordGrantPayload,
  PostOauthRefreshTokenPayload,
  PostOauthResponse,
  PostRevokeOauth,
  PostUserPasswordPayload,
  PostUserSignupPayload,
  PostUserSignupResponse,
  PutUserInvitationPayload,
  PostResendInvitationParams,
  PutUserPasswordPayload
} from './types'
import type {
  GetSignupRelatedAccountParams,
  GetSignupRelatedAccountResponse
} from './types/CheckRelatedAccount.get'

export type DeletePayslipImportOptions = QueryFnParams<{
  pathParams: { import_id: number }
}>

export type PostOauthTokenOptions = QueryFnParams<{
  payload: PostOauthPasswordGrantPayload | PostOauthRefreshTokenPayload
}>

/**
 * POST flow to receive the oauth informations
 */
export const postOauthToken = async (opts?: PostOauthTokenOptions) => {
  const response = await httpServices.core.post<PostOauthResponse>(
    `/oauth/token`,
    opts?.payload,
    {
      withCredentials: true
    }
  )
  return response.data
}

export type PostRevokeOauthTokenOptions = QueryFnParams<{
  payload: PostRevokeOauth
}>
/**
 * POST to revoke the token
 */
export const postRevokeOauthToken = async (
  opts?: PostRevokeOauthTokenOptions
) => {
  const response = await httpServices.core.post(`/oauth/revoke`, opts?.payload)
  return response.data
}

export type PostResetUserPasswordOptions = QueryFnParams<{
  payload: PostUserPasswordPayload
}>
/**
 * POST to reset the password
 */
export const postResetUserPassword = async (
  opts?: PostResetUserPasswordOptions
) => {
  const response = await httpServices.core.post(
    `/users/password`,
    opts?.payload
  )
  return response.data
}

export type PostCheckResetPasswordTokenOptions = QueryFnParams<{
  payload: {
    reset_password_token: string
  }
}>
/**
 * POST to verify the reset-password-
 */
export const postCheckResetPasswordToken = async (
  opts?: PostCheckResetPasswordTokenOptions
) => {
  const response = await httpServices.core.post(
    `/users/check_reset_password_token`,
    opts?.payload
  )
  return response.data
}

export type PutUserPasswordOptions = QueryFnParams<{
  payload: PutUserPasswordPayload
}>
/**
 * POST to update the password current user password
 */
export const putUserPassword = async (opts?: PutUserPasswordOptions) => {
  const response = await httpServices.core.put(`/users/password`, opts?.payload)
  return response.data
}

export type PostCheckExistingEmailOptions = QueryFnParams<{
  payload: PostCheckExistingEmailPayload
}>
/**
 * POST to check if a user email exist
 */
export const postCheckExistingEmail = async (
  opts?: PostCheckExistingEmailOptions
) => {
  const response = await httpServices.core.post<PostCheckExistingEmailResponse>(
    `/users/check_existing_email`,
    opts?.payload
  )
  return response.data
}

export type GetSignupCheckExistingEmailOptions = QueryFnParams<{
  queryParams: GetSignupCheckExistingEmailParams
}>
/**
 * GET to check if a user email exists during signup flow
 */
export const getSignupCheckExistingEmail = async (
  opts?: GetSignupCheckExistingEmailOptions
) => {
  const response =
    await httpServices.core.get<GetSignupCheckExistingEmailResponse>(
      `/signup/check_existing_email`,
      {
        params: opts?.queryParams
      }
    )
  return response.data
}

export type PostSignupResendInvitationOptions = QueryFnParams<{
  queryParams: PostResendInvitationParams
}>
/**
 * POST resend an email for invitation
 */
export const postSignupResendInvitation = async (
  opts: PostSignupResendInvitationOptions
) => {
  const response = await httpServices.core.post(`/signup/resend_invitation`, {
    user: {
      email: opts.queryParams?.email
    }
  })
  return response.data
}

export type GetSignupRelatedAccountOptions = QueryFnParams<{
  queryParams: GetSignupRelatedAccountParams
}>
/**
 * GET related accounts to the signup invitation flow
 */
export const getSignupRelatedAccount = async (
  opts: GetSignupRelatedAccountOptions
) => {
  const response = await httpServices.core.get<GetSignupRelatedAccountResponse>(
    `/signup/related_accounts`,
    {
      params: opts.queryParams
    }
  )
  return response.data
}

export type PostUserSignupOptions = QueryFnParams<{
  payload: PostUserSignupPayload
}>
/**
 * POST to user signup
 */
export const postUserSignup = async (opts?: PostUserSignupOptions) => {
  const response = await httpServices.core.post<PostUserSignupResponse>(
    `/users/sign_up`,
    opts?.payload
  )
  return response.data
}

export type PostImpersonateUserOptions = QueryFnParams<{
  pathParams: {
    account_slug_or_id: string | number
  }
}> & {
  config: {
    super_user_account_id: number
    impersonate_membership_id: number
  }
}
/**
 * POST to impersonate a user
 */
export const postImpersonateUser = async (opts: PostImpersonateUserOptions) => {
  const response = await httpServices.core.post<PostImpersonateUserResponse>(
    `/accounts/${opts.pathParams.account_slug_or_id}/impersonate`,
    null,
    {
      headers: {
        'impersonated-membership-id': String(
          opts.config.impersonate_membership_id
        ),
        'Account-Id': opts.config.super_user_account_id
      }
    }
  )
  return response.data
}

export type GetUserInvitationOptions = QueryFnParams<{
  queryParams: GetUserInvitationQueryParams
}>
/**
 * Get user invitation - validates an invidation with the token
 */
export const getUserInvitation = async (opts: GetUserInvitationOptions) => {
  const response = await httpServices.core.get<GetUserInvitationResponse>(
    `/users/invitation`,
    {
      params: opts.queryParams
    }
  )
  return response.data
}

export type PutUserInvitationOptions = QueryFnParams<{
  payload: PutUserInvitationPayload
}>
/**
 * PUT user invitation - will create a new user
 */
export const putUserInvitation = async (opts: PutUserInvitationOptions) => {
  const response = await httpServices.core.put<null>(
    `/users/invitation`,
    opts.payload
  )
  return response.data
}

export type GetUserConfirmationOptions = QueryFnParams<{
  queryParams: GetUserConfirmationQueryParams
}>
/**
 * Get user confirmation - validates an invidation with the token
 */
export const getUserConfirmation = async (opts: GetUserConfirmationOptions) => {
  const response = await httpServices.core.get<null>(`/users/confirmation`, {
    params: opts.queryParams
  })
  return response.data
}

export type PostCreateUserOptions = QueryFnParams<{
  payload: PostCreateUserPayload
}>

/**
 * POST to create a new user
 */
export const postCreateUser = async (opts?: PostCreateUserOptions) => {
  const response = await httpServices.core.post<PostCreateUserResponse>(
    `/signup/create_user`,
    opts?.payload
  )
  return response.data
}

export type DraftAccountFormData = {
  payload: {
    profile: {
      firstname: string
      lastname: string
      company: string
      job_title: string
      mobile_phone: {
        countryCode: string
        number: string
      }
    }
  }
}

export type PutDraftAccountOptions = {
  payload: DraftAccountFormData
}

/**
 * PUT to update draft account data during signup
 */
export const putDraftAccount = async (opts?: PutDraftAccountOptions) => {
  const response = await httpServices.core.put<null>(
    `/signup/draft_account`,
    opts?.payload
  )
  return response.data
}

export type PostSignupCreateOptions = {
  payload?: Record<string, never>
}

/**
 * POST to complete the signup process
 */
export const postSignupCreate = async (opts?: PostSignupCreateOptions) => {
  const response = await httpServices.core.post<null>(
    `/signup/create`,
    opts?.payload,
    {
      withCredentials: true
    }
  )
  return response.data
}
