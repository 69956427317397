import { useOnboardingTranslation } from '@libs/utils/translations'
import { z } from 'zod'

export const useProfileQualificationSchema = () => {
  const { t } = useOnboardingTranslation()

  return z.object({
    first_name: z
      .string()
      .min(1, t('onboarding.common.forms.validation.required')),
    last_name: z
      .string()
      .min(1, t('onboarding.common.forms.validation.required')),
    company_name: z
      .string()
      .min(1, t('onboarding.common.forms.validation.required')),
    job_title: z
      .string()
      .min(1, t('onboarding.common.forms.validation.required')),
    phone: z.object({
      countryCode: z
        .string()
        .min(1, t('onboarding.common.forms.validation.required')),
      number: z
        .string()
        .min(1, t('onboarding.common.forms.validation.required'))
    })
  })
}

export type ProfileQualificationFormType = z.infer<
  ReturnType<typeof useProfileQualificationSchema>
>
