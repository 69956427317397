import { useGetSignupCheckExistingEmail } from './hooks/useGetSignupCheckExistingEmail'
import { useGetSignupRelatedAccount } from './hooks/useGetSignupRelatedAccount'
import { useGetUserConfirmation } from './hooks/useGetUserConfirmation'
import { useGetUserInvitation } from './hooks/useGetUserInvitation'
import {
  usePostCheckExistingEmail,
  usePostCheckExistingEmailLastMutationState
} from './hooks/usePostCheckExistingEmail'
import { usePostCheckResetPasswordToken } from './hooks/usePostCheckResetPasswordToken'
import { usePostCreateUser } from './hooks/usePostCreateUser'
import { usePostImpersonateUser } from './hooks/usePostImpersonateUser'
import { usePostOauthToken } from './hooks/usePostOauthToken'
import { usePostResetUserPassword } from './hooks/usePostResetUserPassword'
import { usePostRevokeOauthToken } from './hooks/usePostRevokeOauthToken'
import { usePostSignupCreate } from './hooks/usePostSignupCreate'
import { usePostSignupResendInvitation } from './hooks/usePostSignupResendInvitation'
import { usePostUserSignup } from './hooks/usePostUserSignup'
import { usePutDraftAccount } from './hooks/usePutDraftAccount'
import { usePutUserInvitation } from './hooks/usePutUserInvitation'
import { usePutUserPassword } from './hooks/usePutUserPassword'

class AuthService {
  usePostCheckExistingEmail = usePostCheckExistingEmail

  useGetSignupCheckExistingEmail = useGetSignupCheckExistingEmail

  usePostSignupResendInvitation = usePostSignupResendInvitation

  useGetSignupRelatedAccount = useGetSignupRelatedAccount

  usePostCheckResetPasswordToken = usePostCheckResetPasswordToken

  usePostCheckExistingEmailLastMutationState =
    usePostCheckExistingEmailLastMutationState

  usePostOauthToken = usePostOauthToken

  usePostResetUserPassword = usePostResetUserPassword

  usePostRevokeOauthToken = usePostRevokeOauthToken

  usePostUserSignup = usePostUserSignup

  usePutUserPassword = usePutUserPassword

  usePostImpersonateUser = usePostImpersonateUser

  useGetUserInvitation = useGetUserInvitation

  usePutUserInvitation = usePutUserInvitation

  useGetUserConfirmation = useGetUserConfirmation

  usePostCreateUser = usePostCreateUser

  usePostSignupCreate = usePostSignupCreate

  usePutDraftAccount = usePutDraftAccount
}

export const authService = new AuthService()
