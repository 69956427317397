import { Controller } from '@libs/ui/form'
import { useAuthTranslation } from '@libs/utils/translations'
import type { FC } from 'react'
import type { Control } from 'react-hook-form'

type JobTitleSelectProps = {
  control: Control<any>
  name: string
  errorMessage?: string
}

export const JobTitleSelect: FC<JobTitleSelectProps> = ({
  control,
  name,
  errorMessage
}) => {
  const { t } = useAuthTranslation()

  return (
    <Controller.Select
      control={control}
      name={name}
      label={t('authentication.forms.signupCompany.fields.job')}
      errorMessage={errorMessage}
      placeholder={t(
        'authentication.forms.signupCompany.fields.jobPlaceholder'
      )}
      options={[
        {
          value: 'Gérant - Propriétaire',
          label: t('authentication.signUp.formPage.job.owner')
        },
        {
          value: 'Ressources Humaines',
          label: t('authentication.signUp.formPage.job.hr')
        },
        {
          value: 'Opérations',
          label: t('authentication.signUp.formPage.job.operations')
        },
        {
          value: 'Manager',
          label: t('authentication.signUp.formPage.job.manager')
        },
        {
          value: 'Consultant',
          label: t('authentication.signUp.formPage.job.consultant')
        },
        {
          value: 'Etudiant',
          label: t('authentication.signUp.formPage.job.student')
        },
        {
          value: 'Employé',
          label: t('authentication.signUp.formPage.job.employee')
        },
        {
          value: 'Autre',
          label: t('authentication.signUp.formPage.job.other')
        }
      ]}
    />
  )
}
