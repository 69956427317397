import type {
  HttpClientAuthState,
  PostOauthResponse
} from '@libs/data-access/queries'
import {
  appQueryClient,
  coreWSAuth,
  httpServices
} from '@libs/data-access/queries'
import { cookiesService } from '@libs/utils/cookies'
import { DOMAIN } from '@libs/utils/environments'
import { routes } from '@spa/core/router'
import type { NavigateFunction } from 'react-router-dom'

import type { AuthState } from './AuthState.types'

/**
 * Sets authentication cookies with account and user IDs.
 */
export const readAuthCookies = (): AuthState => {
  return {
    accessTokenExpiresAt: cookiesService.readAccessTokenExpiresAt(),
    accountId: cookiesService.readAccountId(),
    userId: cookiesService.readUserId(),
    isAccountCreationInProgress:
      cookiesService.readIsAccountCreationInProgress()
  }
}

/**
 * Sets authentication cookies with account and user IDs.
 */
export const setAuthCookies = (authState: AuthState) => {
  const { cookies } = cookiesService
  if (authState.accountId) {
    cookiesService.set(cookies.ACCOUNT_ID, String(authState.accountId))
  }
  if (authState.userId) {
    cookiesService.set(cookies.USER_ID, String(authState.userId))
  }
  if (authState.accessTokenExpiresAt) {
    cookiesService.set(
      cookies.ACCESS_TOKEN_EXPIRES_AT,
      authState.accessTokenExpiresAt
    )
  }

  if (authState.impersonatedMembershipId) {
    cookiesService.set(
      cookies.IMPERSONATED_MEMBERSHIP_ID,
      String(authState.impersonatedMembershipId)
    )
  }

  cookiesService.setIsAccountCreationInProgress(
    authState.isAccountCreationInProgress ?? false
  )
}

/**
 * Synchronizes the authentication state with the backend services.
 */
export const syncAuthState = (authState: Partial<AuthState>) => {
  httpServices.syncAuthState(authState as HttpClientAuthState)
  coreWSAuth.syncAuthState(authState as HttpClientAuthState)
}

/**
 * Initializes the authentication state with the provided OAuth response,
 * updates the state, sets cookies, syncs the state, and navigates to the root route.
 *
 * The OAuth response contains fields which are directly added to the AuthState:
 * - is_account_creation_in_progress: boolean - indicates if user needs to complete account creation
 * - draft_account_form_data: object - contains saved draft account data when account creation is in progress
 */
export const initAuth = (opts: {
  authResponse: PostOauthResponse & {
    impersonated_membership_id?: number
  }
  navigate: NavigateFunction
  updateAuthState: (newState: AuthState) => void
  redirectTo?: string
}) => {
  const { authResponse, navigate, updateAuthState, redirectTo } = opts

  const firstAccountId = authResponse.info.account_ids[0]
  const newAuthState: AuthState = {
    accessTokenExpiresAt: authResponse.expires_at,
    userId: authResponse.user_id,
    accountId: firstAccountId,
    impersonatedMembershipId: authResponse.impersonated_membership_id,
    isAccountCreationInProgress:
      authResponse.info.is_account_creation_in_progress
  }

  if (authResponse.info.draft_account_form_data) {
    newAuthState.draftAccountFormData =
      authResponse.info.draft_account_form_data
  }

  updateAuthState(newAuthState)
  setAuthCookies(newAuthState)
  syncAuthState(newAuthState)
  setLastSignedInUserId(newAuthState.userId)
  navigate(redirectTo || routes.welcome.rootPath)
}

/**
 * While signing in,
 * Set the last_signed_in_user_id for cache purpose
 */
const setLastSignedInUserId = (userId?: number) => {
  cookiesService.setLastSignedInUserId(userId as number)
}

/**
 * Clears the authentication state, removes cookies, and navigates to the sign-out route.
 */
export const clearAuthState = (
  updateState: (newState: Partial<AuthState>) => void
): void => {
  appQueryClient.clear()
  cookiesService.removeAuthCookies()
  updateState({
    userId: undefined,
    accountId: undefined,
    impersonatedMembershipId: undefined
  })
  syncAuthState({
    userId: undefined,
    accountId: undefined,
    impersonatedMembershipId: undefined
  })
}

/**
 * Updates the authentication account ID, syncs the state,
 * And redirects to the root route to flush the memory cache.
 */
export const updateAuthAccount = (accountId: number): void => {
  setAuthCookies({ accountId } as AuthState)
  window.location.href = routes.welcome.rootPath
}

/**
 * Checks if the current subdomain is different from the main domain.
 */
export const isSubDomainAuth = () => {
  const subdomain = DOMAIN?.split('.')[0]
  const currentSubDomain = getSubDomainAccountSlug()
  return currentSubDomain !== subdomain
}

/**
 * Retrieves the subdomain from the current window location.
 */
export const getSubDomainAccountSlug = () => {
  return window.location.host.split('.')[0]
}

/**
 * Impersonation occurs when a user:
 * - Sign-in to a sub-domain (e.g. ninkasi.app.combohr.com)
 * - Is a super_user (aka combo user)
 */
export const isImpersonationFlow = ({
  isSuperUser
}: {
  isSuperUser: boolean
}) => {
  return isSubDomainAuth() && isSuperUser
}

/**
 * Check if the current context is impersonation
 */
export const isImpersonationContext = () => {
  const impersonatedMembershipId = cookiesService.read(
    cookiesService.cookies.IMPERSONATED_MEMBERSHIP_ID
  )
  return Boolean(impersonatedMembershipId)
}
