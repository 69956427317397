/**
 * Returns the default country code based on the language
 * @param language - The language code (e.g. 'fr', 'es', 'de', etc.)
 * @returns The country code as a string
 */
export const getDefaultCountryCode = (language: string): string => {
  switch (language) {
    case 'fr':
      return '33'
    case 'es':
      return '34'
    case 'de':
      return '49'
    case 'it':
      return '39'
    case 'pt':
      return '351'
    default:
      return '33'
  }
}
