import { Flex } from '@libs/ui/ds'
import { AuthNavbar } from '@spa/scenes/Auth/AuthNavbar/AuthNavbar'
import { AuthNavbarProvider } from '@spa/scenes/Auth/shared/services/authNavbar/AuthNavbarProvider'
import type { FC, PropsWithChildren } from 'react'

import { AuthHeroImage } from './AuthHeroImage/AuthHeroImage'

export const SignupSplitLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AuthNavbarProvider>
      <div className='flex'>
        <div className='w-full'>
          <Flex className='md:max-h-20'>
            <AuthNavbar />
          </Flex>
          <Flex className='w-full'>
            <Flex className='md:m-auto'>
              <Flex className='w-screen px-4 py-8 md:my-14 md:max-w-120'>
                {children}
              </Flex>
            </Flex>
          </Flex>
        </div>
        <div className='hidden min-h-screen w-1/2 max-w-[40rem] bg-brand-beige-50 pt-20 lg:block'>
          <AuthHeroImage />
        </div>
      </div>
    </AuthNavbarProvider>
  )
}
