import { CalendarIcon } from '@libs/ui/ds/assets/icons'
import type { FC } from 'react'

import { InputWrap } from '../../../../forms/InputWrap/base/InputWrap'
import { InputBase } from '../../../../inputs/InputBase/base/InputBase'
import { Popover } from '../../../../overlays/Popover/base/Popover'
import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'
import { CalendarPicker } from '../../../datePickerUtils/subs/CalendarPicker'
import { CalendarRangeArrow } from '../../../datePickerUtils/subs/CalendarRangeArrow'
import { DatePickerFocusWrap } from '../../../datePickerUtils/subs/DatePickerFocusWrap'
import { DatePickerInputClearButton } from '../../../datePickerUtils/subs/DatePickerInputClearButton'
import { PickerPresetList } from '../../../datePickerUtils/subs/PickerPresets/PickerPresetList'
import { dateRangeConfig } from '../../../datePickerUtils/utils/dateRangeConfig'
import { useDatePickerOpened } from '../../../datePickerUtils/utils/useDatePickerOpened/useDatePickerOpened'

import type { DateRangePickerInputProps } from './DateRangePickerInput.types'

export const DateRangePickerInput: FC<DateRangePickerInputProps> = ({
  value,
  defaultValue,
  onChange,
  onBlur,
  presets,

  /** Popover props */
  onOpenedChange,
  opened,
  dropdownPosition = 'bottom-start',

  /** InputWrap Props */
  label,
  disabled = false,
  className,
  required = false,
  errorMessage,
  desc,
  readOnly = false,
  rightSection,
  onClear,
  clearable = false,
  pickerOnly,

  /** Calendar Props */
  allowSingleDateInRange,
  maxLevel,
  placeholder,
  excludeDate,
  minDate,
  maxDate,
  defaultDate,
  ...other
}) => {
  const openedValues = useDatePickerOpened({ onOpenedChange, opened })
  const dateRangeSync = dateRangeConfig.useSyncDate({
    value,
    defaultValue,
    onBlur,
    onChange,
    minDate,
    maxDate,
    defaultDate
  })

  return (
    <Popover
      dropdownHeight='auto'
      dropdownWidth='auto'
      dropdownPosition={dropdownPosition}
      opened={openedValues.opened}
      disabled={disabled || readOnly}
    >
      <DatePickerFocusWrap setOpen={openedValues.setOpened}>
        <InputWrap
          label={label}
          disabled={disabled}
          className={className}
          required={required}
          errorMessage={errorMessage || dateRangeSync.errorMessage}
          desc={desc}
          {...other}
          field={
            <Popover.Target>
              <Flex row className='flex-nowrap items-center gap-2'>
                <InputBase
                  data-testid='date-picker-input'
                  className='grow'
                  value={dateRangeSync.strValue?.start}
                  onChange={e => {
                    dateRangeSync.onChangeStart(e.target.value)
                  }}
                  type='date'
                  onClick={e => e.preventDefault()}
                  placeholder={placeholder}
                  readOnly={readOnly || pickerOnly}
                  disabled={disabled}
                  error={Boolean(errorMessage || dateRangeSync.errorMessage)}
                  leftIcon={<CalendarIcon />}
                  rightSection={
                    <DatePickerInputClearButton
                      disabled={disabled}
                      fallback={rightSection}
                      clearable={clearable}
                      readOnly={readOnly}
                      onClear={e => {
                        onClear?.(e)
                        dateRangeSync.resetStart()
                      }}
                    />
                  }
                />
                <CalendarRangeArrow />
                <InputBase
                  data-testid='date-picker-input'
                  className='grow'
                  value={dateRangeSync.strValue?.end}
                  onChange={e => dateRangeSync.onChangeEnd(e.target.value)}
                  type='date'
                  placeholder={placeholder}
                  readOnly={readOnly || pickerOnly}
                  disabled={disabled}
                  onClick={e => e.preventDefault()}
                  error={Boolean(errorMessage || dateRangeSync.errorMessage)}
                  leftIcon={<CalendarIcon />}
                  rightSection={
                    <DatePickerInputClearButton
                      readOnly={readOnly}
                      disabled={disabled}
                      fallback={rightSection}
                      clearable={clearable}
                      onClear={e => {
                        onClear?.(e)
                        dateRangeSync.resetEnd()
                      }}
                    />
                  }
                />
              </Flex>
            </Popover.Target>
          }
        />

        <Popover.Dropdown>
          <Flex className='gap-5'>
            <CalendarPicker
              value={dateRangeSync.value}
              date={dateRangeSync.date}
              onDateChange={dateRangeSync.onDateChange}
              onChange={dateRangeSync.onChange}
              minDate={dateRangeSync.minDate}
              maxDate={dateRangeSync.maxDate}
              defaultDate={dateRangeSync.defaultDate}
              type='range'
              componentType='date'
              allowSingleDateInRange={allowSingleDateInRange}
              numberOfColumns={2}
              maxLevel={maxLevel}
              excludeDate={excludeDate}
            />
            <PickerPresetList
              onChange={val => {
                const startDate = new Date(val.start)
                const endDate = new Date(val.end)
                dateRangeSync.onDateChange(val.isPast ? endDate : startDate)
                dateRangeSync.onChange([startDate, endDate])
              }}
              presets={presets}
              value={dateRangeSync.strValue}
            />
          </Flex>
        </Popover.Dropdown>
      </DatePickerFocusWrap>
    </Popover>
  )
}

export type { DateRangePickerInputProps } from './DateRangePickerInput.types'
