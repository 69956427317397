import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../types'
import type { PutDraftAccountOptions } from '../auth.api'
import { putDraftAccount } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'

export const usePutDraftAccount = (
  mutationOptions?: UseAppMutationOptions<
    null,
    ApiErrorResponse,
    PutDraftAccountOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.putDraftAccount(),
    mutationFn: (opts: PutDraftAccountOptions) => putDraftAccount(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}
