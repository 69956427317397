import { Loader } from '@libs/ui/ds'
import { SignupSplitLayout } from '@spa/bootstrap/GuestApp/SignupSplitLayout'

import { ProfileQualificationForm } from './ProfileQualificationForm'
import { useLoadDraftAccountData } from './hooks/useLoadDraftAccountData'

export const ProfileQualificationStep = () => {
  const { draftData, isLoading } = useLoadDraftAccountData()

  return (
    <SignupSplitLayout>
      {isLoading ? (
        <Loader />
      ) : (
        <ProfileQualificationForm defaultValues={draftData} />
      )}
    </SignupSplitLayout>
  )
}
