export const COOKIES_NAMES = {
  CSRF: '_ss_csrf',
  ACCESS_TOKEN_EXPIRES_AT: '_ss_token_expires_at',
  ACCOUNT_ID: '_ss_ad',
  USER_ID: '_ss_ud',
  REMEMBER_ME: '_ss_remember_me',
  INTERCOM_USER_HASH: '_ss_intercom_user_hash',
  LAST_SIGNED_IN_USER_ID: '_ss_last_signed_in_user_id',
  IMPERSONATED_MEMBERSHIP_ID: '_ss_impersonated_membership_id',
  ACCOUNT_SUBSCRIPTION_STATUS: '_ss_ss',
  GOOGLE_ID: '_ga',
  GOOGLE_UTM_MEDIUM: 'utm_medium_cookie',
  GOOGLE_UTM_SOURCE: 'utm_source_cookie',
  GOOGLE_UTM_CAMPAIGN: 'utm_campaign_cookie',
  GOOGLE_UTM_CONTENT: 'utm_content_cookie',
  GOOGLE_UTM_TERM: 'utm_term_cookie',
  FIRSTPAGE: 'firstpage',
  IS_ACCOUNT_CREATION_IN_PROGRESS: '_ss_account_creation_in_progress'
} as const

export type CookiesNames = (typeof COOKIES_NAMES)[keyof typeof COOKIES_NAMES]
