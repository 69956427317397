import { CalendarIcon } from '@libs/ui/ds/assets/icons'
import type { FC } from 'react'
import React from 'react'

import { InputWrap } from '../../../../forms/InputWrap/base/InputWrap'
import { InputBase } from '../../../../inputs/InputBase/base/InputBase'
import { Popover } from '../../../../overlays/Popover/base/Popover'
import { CalendarPicker } from '../../../datePickerUtils/subs/CalendarPicker'
import { DatePickerFocusWrap } from '../../../datePickerUtils/subs/DatePickerFocusWrap'
import { DatePickerInputClearButton } from '../../../datePickerUtils/subs/DatePickerInputClearButton'
import { monthConfig } from '../../../datePickerUtils/utils/monthConfig'
import { useDatePickerOpened } from '../../../datePickerUtils/utils/useDatePickerOpened/useDatePickerOpened'

import type { MonthPickerInputProps } from './MonthPickerInput.types'

export const MonthPickerInput: FC<MonthPickerInputProps> = ({
  value,
  onBlur,
  onChange,
  defaultValue,

  /** Popover props */
  onOpenedChange,
  opened,
  dropdownPosition = 'bottom-start',

  /** InputWrap Props */
  label,
  disabled = false,
  className,
  required,
  errorMessage,
  desc,
  readOnly = false,
  rightSection,
  onClear,
  clearable = false,
  pickerOnly,

  /** Calendar Props */
  maxLevel,
  placeholder,
  excludeDate,
  minDate,
  maxDate,
  defaultDate,
  ...other
}) => {
  const openedValues = useDatePickerOpened({ onOpenedChange, opened })
  const syncMonth = monthConfig.useSyncDate({
    value,
    defaultValue,
    onBlur,
    onChange,
    minDate,
    maxDate,
    defaultDate
  })

  return (
    <Popover
      dropdownHeight='l'
      dropdownWidth='auto'
      dropdownPosition={dropdownPosition}
      opened={openedValues.opened}
      disabled={disabled || readOnly}
    >
      <DatePickerFocusWrap setOpen={openedValues.setOpened}>
        <InputWrap
          label={label}
          disabled={disabled}
          className={className}
          required={required}
          errorMessage={errorMessage || syncMonth.errorMessage}
          desc={desc}
          {...other}
          field={
            <Popover.Target>
              <InputBase
                data-testid='date-picker-input'
                value={syncMonth.strValue}
                onChange={e => syncMonth.onChangeStr(e.target.value)}
                type='month'
                placeholder={placeholder}
                readOnly={readOnly || pickerOnly}
                disabled={disabled}
                error={Boolean(errorMessage || syncMonth.errorMessage)}
                leftIcon={<CalendarIcon />}
                rightSection={
                  <DatePickerInputClearButton
                    disabled={disabled}
                    readOnly={readOnly}
                    fallback={rightSection}
                    clearable={clearable}
                    onClear={e => {
                      onClear?.(e)
                      syncMonth.reset()
                    }}
                  />
                }
              />
            </Popover.Target>
          }
        />
        <Popover.Dropdown>
          <CalendarPicker
            value={syncMonth.value}
            date={syncMonth.date}
            onDateChange={syncMonth.onDateChange}
            onChange={val => {
              openedValues.setOpened(false)
              syncMonth.onChange(val)
            }}
            minDate={syncMonth.minDate}
            maxDate={syncMonth.maxDate}
            defaultDate={syncMonth.defaultDate}
            type='default'
            componentType='month'
            numberOfColumns={1}
            maxLevel={maxLevel}
            excludeDate={excludeDate}
          />
        </Popover.Dropdown>
      </DatePickerFocusWrap>
    </Popover>
  )
}

export type { MonthPickerInputProps } from './MonthPickerInput.types'
