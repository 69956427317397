/* eslint-disable import/no-named-as-default */

import { SuspenseWrap } from '@libs/ui/ds'
import { routes } from '@spa/core/router'
import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import { SignUpWrapper } from './SignUpWrapper'
import { UnauthenticatedRedirects } from './UnauthenticatedRedirects'

const ForgottenPassword = lazy(
  () => import('@spa/scenes/Auth/ForgottenPasswordLegacy/ForgottenPassword')
)
const EditPassword = lazy(
  () => import('@spa/scenes/Auth/EditPasswordLegacy/EditPassword')
)
const SignIn = lazy(() => import('@spa/scenes/Auth/SignInLegacy/SignIn'))
const SignOut = lazy(() => import('@spa/scenes/Auth/SignOut/SignOut'))
const SignUp = lazy(() => import('@spa/scenes/Auth/SignUpLegacy/SignUp'))
const SignUpCompany = lazy(
  () => import('@spa/scenes/Auth/SignUpLegacy/SignUpCompany')
)

const UserInvitation = lazy(
  () => import('@spa/scenes/Auth/UserInvitation/UserInvitation')
)
const UserConfirmation = lazy(
  () => import('@spa/scenes/Auth/UserConfirmation/UserConfirmation')
)

export const UnauthenticatedAppRoutes = React.memo(() => {
  return (
    <SuspenseWrap>
      <Routes>
        <Route path={routes.signIn.path} element={<SignIn />} />
        <Route path={routes.signOut.path} element={<SignOut />} />
        <Route
          path={routes.signUp.path}
          element={
            <SignUpWrapper>
              <SignUp />
            </SignUpWrapper>
          }
        />
        <Route
          path={routes.signUpCompany.path}
          element={
            <SignUpWrapper>
              <SignUpCompany />
            </SignUpWrapper>
          }
        />

        <Route
          path={routes.forgottenPassword.path}
          element={<ForgottenPassword />}
        />
        <Route path={routes.editPassword.path} element={<EditPassword />} />
        <Route
          path={routes.signUpInvitation.path}
          element={<UserInvitation />}
        />
        <Route
          path={routes.userConfirmation.path}
          element={<UserConfirmation />}
        />

        {/* default redirect to signin page */}
        <Route path='*' element={<UnauthenticatedRedirects />} />
      </Routes>
    </SuspenseWrap>
  )
})
