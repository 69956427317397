import { zodResolver } from '@hookform/resolvers/zod'
import { authService } from '@libs/data-access/queries'
import {
  AlertErrorAdapter,
  Button,
  Flex,
  ProgressStepper,
  Text,
  Title,
  notifications
} from '@libs/ui/ds'
import { BaseForm, Controller } from '@libs/ui/form'
import i18n, { useOnboardingTranslation } from '@libs/utils/translations'
import type { DraftAccountFormData } from '@spa/scenes/Auth/shared/services/authState/AuthState.types'
import { getDefaultCountryCode } from '@spa/utils/getDefaultCountryCode'
import type { FC } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'

import { JobTitleSelect } from './JobTitleSelect/JobTitleSelect'
import { PhoneInput } from './PhoneInput/PhoneInput'
import {
  useProfileQualificationSchema,
  type ProfileQualificationFormType
} from './ProfileQualificationForm.validations'

type ProfileQualificationFormProps = {
  defaultValues: DraftAccountFormData
}

export const ProfileQualificationForm: FC<ProfileQualificationFormProps> = ({
  defaultValues
}) => {
  const { t, tCommon } = useOnboardingTranslation()
  const updateDraftAccountMutation = authService.usePutDraftAccount()
  const schema = useProfileQualificationSchema()

  const methods = useForm<ProfileQualificationFormType>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
    defaultValues: {
      first_name: defaultValues.profile?.firstname || '',
      last_name: defaultValues.profile?.lastname || '',
      company_name: defaultValues.profile?.company || '',
      job_title: defaultValues.profile?.job_title || '',
      phone: {
        countryCode:
          defaultValues.profile?.mobile_phone?.countryCode ||
          getDefaultCountryCode(i18n.language),
        number: defaultValues.profile?.mobile_phone?.number || ''
      }
    }
  })

  const {
    control,
    formState: { errors, isSubmitting },
    setValue
  } = methods

  const onSubmitHandler: SubmitHandler<
    ProfileQualificationFormType
  > = async formData => {
    try {
      await updateDraftAccountMutation.mutateAsync({
        payload: {
          // The backend expects a payload key
          payload: {
            profile: {
              firstname: formData.first_name,
              lastname: formData.last_name,
              company: formData.company_name,
              job_title: formData.job_title,
              mobile_phone: {
                countryCode: formData.phone.countryCode,
                number: formData.phone.number
              }
            }
          }
        }
      })

      // TODO: Enable this once we're ready to handle signup creation
      // https://snapshiftapp.atlassian.net/browse/AB-1190
      // await authService.usePostSignupCreate().mutateAsync({})
      // navigate('/')
    } catch (error) {
      notifications.error({
        message: t('onboarding.qualification.error')
      })
    }
  }

  return (
    <Flex className='w-full'>
      <Flex className='mb-10 flex-col gap-4'>
        <ProgressStepper currentStep={1} totalSteps={2} color='primary' />
        <Title size={2}>{t('onboarding.qualification.title')}</Title>
        <Text className='text-s text-neutral-600'>
          {t('onboarding.qualification.subtitle')}
        </Text>
      </Flex>

      <BaseForm<ProfileQualificationFormType>
        methods={methods}
        onSubmit={onSubmitHandler}
        role='form'
      >
        <Flex className='flex-col gap-4'>
          <AlertErrorAdapter
            error={updateDraftAccountMutation.error?.error}
            data-testid='alert-form-error'
          />
          <Flex className='flex-col gap-4 md:flex-row'>
            <Controller.TextInput
              name='first_name'
              control={control}
              required
              label={t('onboarding.common.forms.firstName')}
              errorMessage={errors?.first_name?.message}
              autoComplete='given-name'
            />

            <Controller.TextInput
              name='last_name'
              control={control}
              required
              label={t('onboarding.common.forms.lastName')}
              errorMessage={errors?.last_name?.message}
              autoComplete='family-name'
            />
          </Flex>

          <Controller.TextInput
            name='company_name'
            control={control}
            required
            label={t('onboarding.common.forms.companyName')}
            errorMessage={errors?.company_name?.message}
            autoComplete='organization'
          />

          <JobTitleSelect
            control={control}
            name='job_title'
            errorMessage={errors?.job_title?.message}
          />

          <PhoneInput
            control={control}
            name='phone'
            label={t('onboarding.common.forms.phoneNumber')}
            error={errors?.phone?.number}
            required
            setValue={setValue}
          />

          <Flex className='mt-4'>
            <Button type='submit' loading={isSubmitting} fillWidth>
              {tCommon('action.next')}
            </Button>
          </Flex>
        </Flex>
      </BaseForm>
    </Flex>
  )
}
