import { useDSTranslation } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { Text } from '../../../typography/Text/base/Text'

const progressStepperCVA = {
  wrapper: cva(['flex w-full flex-col gap-y-2']),
  barsContainer: cva(['flex w-full gap-x-2']),
  bar: cva(['h-1 flex-1 rounded-md'], {
    variants: {
      color: {
        default: 'bg-neutral-200',
        primary: 'bg-brand-green-700',
        danger: 'bg-semantic-red-600',
        warning: 'bg-semantic-orange-400',
        success: 'bg-semantic-green-700'
      }
    },
    defaultVariants: {
      color: 'default'
    }
  })
}

export type ProgressStepperProps = {
  /**
   * Current step
   */
  currentStep: number
  /**
   * Total number of steps
   */
  totalSteps: number
  /**
   * Color variant for the filled bars
   */
  color?: 'primary' | 'danger' | 'warning' | 'success'
  /**
   * Optional className for the wrapper
   */
  className?: string
}

export const ProgressStepper: FC<ProgressStepperProps> = ({
  currentStep,
  totalSteps,
  color = 'primary',
  className
}) => {
  const { t } = useDSTranslation()

  return (
    <div
      className={progressStepperCVA.wrapper({ className })}
      data-testid='progress-stepper'
    >
      <Text className='text-s text-neutral-600'>
        {t('ds.components.progressStepper.stepCounter', {
          current: currentStep,
          total: totalSteps
        })}
      </Text>
      <div className={progressStepperCVA.barsContainer()}>
        {Array.from({ length: currentStep }).map((_, index) => (
          <div
            key={`filled-${index}`}
            className={progressStepperCVA.bar({
              color
            })}
            data-testid='filled-bar'
          />
        ))}
        {Array.from({ length: totalSteps - currentStep }).map((_, index) => (
          <div
            key={`neutral-${index}`}
            className={progressStepperCVA.bar({ color: 'default' })}
            data-testid='unfilled-bar'
          />
        ))}
      </div>
    </div>
  )
}
