import { IS_ENABLE_SIGNUP_FLOW } from '@libs/utils/environments'
import { UnauthenticatedApp } from '@spa/bootstrap/UnauthenticatedApp/UnauthenticatedApp'
import { CustomerCareMenu } from '@spa/features/externals/intercom'
import {
  AuthenticationRefreshGuard,
  useAuthState
} from '@spa/scenes/Auth/shared/services'

import { ProfileQualificationStep } from './AccountQualification/ProfileQualificationStep'
import { AuthenticatedApp } from './AuthenticatedApp/AuthenticatedApp'
import { GuestApp } from './GuestApp/GuestApp'

export const AppEntryController = () => {
  const authState = useAuthState()

  if (IS_ENABLE_SIGNUP_FLOW) {
    if (authState.isAuthenticated) {
      const isAccountCreationInProgress = Boolean(
        authState.auth.isAccountCreationInProgress
      )

      return (
        <AuthenticationRefreshGuard>
          <CustomerCareMenu />
          {isAccountCreationInProgress ? (
            <ProfileQualificationStep />
          ) : (
            <AuthenticatedApp />
          )}
        </AuthenticationRefreshGuard>
      )
    }
    return <GuestApp />
  }

  if (authState.isAuthenticated) {
    return (
      <AuthenticationRefreshGuard>
        <CustomerCareMenu />
        <AuthenticatedApp />
      </AuthenticationRefreshGuard>
    )
  }
  return (
    <>
      <UnauthenticatedApp />
      <CustomerCareMenu />
    </>
  )
}
