import type { DatePickerInputProps } from '@libs/ui/ds'
import { useUncontrolled } from '@libs/ui/ds'
import type { MomentInput } from 'moment'
import { useEffect, useState } from 'react'

import { toDate, toString, useErrorMessage } from './formats'

export const dateConfig = {
  /**
   * Format string format YYYY-MM-DD to Date object
   */
  toDate: (value?: string) =>
    toDate({
      value,
      format: 'YYYY-MM-DD'
    }),

  /**
   * Format Date object to string format YYYY-MM-DD
   */
  toStr: (value: MomentInput) =>
    toString({
      value,
      format: 'YYYY-MM-DD'
    }),

  /**
   * Transforms and synchronize date/value attributes for the simple date-input-picker
   */
  useSyncDate: (opts: {
    value?: DatePickerInputProps['value']
    onChange?: DatePickerInputProps['onChange']
    onBlur?: DatePickerInputProps['onBlur']
    defaultValue?: DatePickerInputProps['defaultValue']
    minDate?: DatePickerInputProps['minDate']
    maxDate?: DatePickerInputProps['maxDate']
    defaultDate?: DatePickerInputProps['defaultDate']
  }) => {
    const [isDirty, setIsDirty] = useState(false)
    const [controlledValue, setControlledValue] = useUncontrolled(opts)
    const [controlledDate, setControlledDate] = useUncontrolled({
      defaultValue: opts.defaultDate || controlledValue
    })

    const handleSetControlledValue = (val: string) => {
      if (!isDirty) {
        setIsDirty(true)
      }
      setControlledValue(val)
    }

    useEffect(() => {
      if (controlledValue) {
        setControlledDate(controlledValue)
      }
    }, [controlledValue])

    return {
      onChangeStr: handleSetControlledValue,
      strValue: controlledValue,

      /**
       * Calendar props
       */
      value: dateConfig.toDate(controlledValue),
      onChange: val => {
        handleSetControlledValue(dateConfig.toStr(val))
      },
      reset: () => handleSetControlledValue(''),
      date: dateConfig.toDate(controlledDate),
      onDateChange: (val: Date) => setControlledDate(dateConfig.toStr(val)),
      minDate: dateConfig.toDate(opts.minDate),
      maxDate: dateConfig.toDate(opts.maxDate),
      defaultDate: dateConfig.toDate(opts.defaultDate),
      errorMessage: useErrorMessage({
        value: controlledValue,
        isDirty,
        minDate: opts.minDate,
        maxDate: opts.maxDate
      })
    }
  }
}
