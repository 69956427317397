import { type FC } from 'react'

import { ControlledAsyncAutocomplete } from './compound/ControlledAsyncAutocomplete/ControlledAsyncAutocomplete'
import { ControlledAsyncSelect } from './compound/ControlledAsyncSelect/ControlledAsyncSelect'
import { ControlledAutocomplete } from './compound/ControlledAutocomplete/ControlledAutocomplete'
import { ControlledCheckbox } from './compound/ControlledCheckbox/ControlledCheckbox'
import { ControlledDatePickerInput } from './compound/ControlledDatePickerInput/ControlledDatePickerInput'
import { ControlledDateRangePickerInput } from './compound/ControlledDateRangePickerInput/ControlledDateRangePickerInput'
import { ControlledDropzone } from './compound/ControlledDropzone/ControlledDropzone'
import { ControlledMonthPickerInput } from './compound/ControlledMonthPickerInput/ControlledMonthPickerInput'
import { ControlledMonthRangePickerInput } from './compound/ControlledMonthRangePickerInput/ControlledMonthRangePickerInput'
import { ControlledNativeSelect } from './compound/ControlledNativeSelect/ControlledNativeSelect'
import { ControlledNumberInput } from './compound/ControlledNumberInput/ControlledNumberInput'
import { ControlledPasswordInput } from './compound/ControlledPasswordInput/ControlledPasswordInput'
import { ControlledPhoneInput } from './compound/ControlledPhoneInput/ControlledPhoneInput'
import { ControlledSelect } from './compound/ControlledSelect/ControlledSelect'
import { ControlledSelectGroup } from './compound/ControlledSelectGroup/ControlledSelectGroup'
import { ControlledSwitch } from './compound/ControlledSwitch/ControlledSwitch'
import { ControlledTextArea } from './compound/ControlledTextArea/ControlledTextArea'
import { ControlledTextInput } from './compound/ControlledTextInput/ControlledTextInput'

const BaseController: FC<never> = () => {
  throw new Error(
    'Controller should only be instanciated using the compound pattern "Controller.XXX"'
  )
}

type ControllerCompoundType = {
  AsyncAutocomplete: typeof ControlledAsyncAutocomplete
  AsyncSelect: typeof ControlledAsyncSelect
  Autocomplete: typeof ControlledAutocomplete
  Checkbox: typeof ControlledCheckbox
  DatePickerInput: typeof ControlledDatePickerInput
  DateRangePickerInput: typeof ControlledDateRangePickerInput
  Dropzone: typeof ControlledDropzone
  MonthPickerInput: typeof ControlledMonthPickerInput
  MonthRangePickerInput: typeof ControlledMonthRangePickerInput
  NativeSelect: typeof ControlledNativeSelect
  NumberInput: typeof ControlledNumberInput
  PasswordInput: typeof ControlledPasswordInput
  PhoneInput: typeof ControlledPhoneInput
  Select: typeof ControlledSelect
  SelectGroup: typeof ControlledSelectGroup
  Switch: typeof ControlledSwitch
  TextArea: typeof ControlledTextArea
  TextInput: typeof ControlledTextInput
}

const TypedController = BaseController as typeof BaseController &
  ControllerCompoundType

TypedController.displayName = 'Controller'
TypedController.AsyncAutocomplete = ControlledAsyncAutocomplete
TypedController.AsyncSelect = ControlledAsyncSelect
TypedController.Autocomplete = ControlledAutocomplete
TypedController.Checkbox = ControlledCheckbox
TypedController.DatePickerInput = ControlledDatePickerInput
TypedController.DateRangePickerInput = ControlledDateRangePickerInput
TypedController.Dropzone = ControlledDropzone
TypedController.MonthPickerInput = ControlledMonthPickerInput
TypedController.MonthRangePickerInput = ControlledMonthRangePickerInput
TypedController.NativeSelect = ControlledNativeSelect
TypedController.NumberInput = ControlledNumberInput
TypedController.PasswordInput = ControlledPasswordInput
TypedController.PhoneInput = ControlledPhoneInput
TypedController.Select = ControlledSelect
TypedController.SelectGroup = ControlledSelectGroup
TypedController.Switch = ControlledSwitch
TypedController.TextArea = ControlledTextArea
TypedController.TextInput = ControlledTextInput

export const Controller = TypedController

export type { ControlledAsyncAutocompleteProps } from './compound/ControlledAsyncAutocomplete/ControlledAsyncAutocomplete.types'
export type { ControlledAutocompleteProps } from './compound/ControlledAutocomplete/ControlledAutocomplete.types'
export type { ControlledAyncSelectProps } from './compound/ControlledAsyncSelect/ControlledAsyncSelect.types'
export type { ControlledCheckboxProps } from './compound/ControlledCheckbox/ControlledCheckbox.types'
export type { ControlledDatePickerInputProps } from './compound/ControlledDatePickerInput/ControlledDatePickerInput.types'
export type { ControlledDateRangePickerInputProps } from './compound/ControlledDateRangePickerInput/ControlledDateRangePickerInput.types'
export type { ControlledDropzoneProps } from './compound/ControlledDropzone/ControlledDropzone.types'
export type { ControlledMonthPickerInputProps } from './compound/ControlledMonthPickerInput/ControlledMonthPickerInput.types'
export type { ControlledMonthRangePickerInputProps } from './compound/ControlledMonthRangePickerInput/ControlledMonthRangePickerInput.types'
export type { ControlledNativeSelectProps } from './compound/ControlledNativeSelect/ControlledNativeSelect.types'
export type { ControlledNumberInputProps } from './compound/ControlledNumberInput/ControlledNumberInput.types'
export type { ControlledPasswordInputProps } from './compound/ControlledPasswordInput/ControlledPasswordInput.types'
export type { ControlledPhoneInputProps } from './compound/ControlledPhoneInput/ControlledPhoneInput.types'
export type { ControlledSelectGroupProps } from './compound/ControlledSelectGroup/ControlledSelectGroup.types'
export type { ControlledSelectProps } from './compound/ControlledSelect/ControlledSelect.types'
export type { ControlledSwitchProps } from './compound/ControlledSwitch/ControlledSwitch.types'
export type { ControlledTextAreaProps } from './compound/ControlledTextArea/ControlledTextArea.types'
export type { ControlledTextInputProps } from './compound/ControlledTextInput/ControlledTextInput.types'
export type { ControllerProps } from './Controller.types'
