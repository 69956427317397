import { Flex } from '@libs/ui/ds'
import { AuthNavbar } from '@spa/scenes/Auth/AuthNavbar/AuthNavbar'
import { AuthNavbarProvider } from '@spa/scenes/Auth/shared/services/authNavbar/AuthNavbarProvider'
import type { FC, PropsWithChildren } from 'react'

export const AuthCardLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AuthNavbarProvider>
      <div className='h-screen md:bg-brand-beige-50'>
        <Flex className='md:max-h-20'>
          <AuthNavbar className='md:bg-brand-beige-50' />
        </Flex>
        <Flex className='w-full'>
          <Flex className='md:m-auto'>
            <Flex className='w-screen px-4 py-8 md:my-14 md:max-w-120 md:rounded-lg md:border-1 md:border-solid md:border-neutral-200 md:bg-neutral-50 md:p-10'>
              {children}
            </Flex>
          </Flex>
        </Flex>
      </div>
    </AuthNavbarProvider>
  )
}
