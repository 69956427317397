import { Flex, InputWrap, type SelectOption } from '@libs/ui/ds'
import { Controller } from '@libs/ui/form'
import i18n from '@libs/utils/translations'
import { getDefaultCountryCode } from '@spa/utils/getDefaultCountryCode'
import { useEffect, useMemo } from 'react'
import type {
  Control,
  FieldError,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue
} from 'react-hook-form'
import { useWatch } from 'react-hook-form'

export type PhoneValue = {
  countryCode: string
  number: string
}

type PhoneInputProps<T extends FieldValues> = {
  control: Control<T>
  error?: FieldError
  label: string
  required?: boolean
  name: Path<T>
  setValue: UseFormSetValue<T>
}

const COUNTRY_OPTIONS: SelectOption[] = [
  {
    value: '33',
    label: '🇫🇷 +33',
    optionLabel: '🇫🇷 France +33'
  },
  {
    value: '34',
    label: '🇪🇸 +34',
    optionLabel: '🇪🇸 Spain +34'
  },
  {
    value: '43',
    label: '🇦🇹 +43',
    optionLabel: '🇦🇹 Austria +43'
  },
  {
    value: '32',
    label: '🇧🇪 +32',
    optionLabel: '🇧🇪 Belgium +32'
  },
  {
    value: '1',
    label: '🇨🇦 +1',
    optionLabel: '🇨🇦 Canada +1'
  },
  {
    value: '594',
    label: '🇬🇫 +594',
    optionLabel: '🇬🇫 French Guiana +594'
  },
  {
    value: '689',
    label: '🇵🇫 +689',
    optionLabel: '🇵🇫 French Polynesia +689'
  },
  {
    value: '49',
    label: '🇩🇪 +49',
    optionLabel: '🇩🇪 Germany +49'
  },
  {
    value: '590',
    label: '🇬🇵 +590',
    optionLabel: '🇬🇵 Guadeloupe +590'
  },
  {
    value: '39',
    label: '🇮🇹 +39',
    optionLabel: '🇮🇹 Italy +39'
  },
  {
    value: '352',
    label: '🇱🇺 +352',
    optionLabel: '🇱🇺 Luxembourg +352'
  },
  {
    value: '596',
    label: '🇲🇶 +596',
    optionLabel: '🇲🇶 Martinique +596'
  },
  {
    value: '212',
    label: '🇲🇦 +212',
    optionLabel: '🇲🇦 Morocco +212'
  },
  {
    value: '31',
    label: '🇳🇱 +31',
    optionLabel: '🇳🇱 Netherlands +31'
  },
  {
    value: '687',
    label: '🇳🇨 +687',
    optionLabel: '🇳🇨 New Caledonia +687'
  },
  {
    value: '351',
    label: '🇵🇹 +351',
    optionLabel: '🇵🇹 Portugal +351'
  },
  {
    value: '262',
    label: '🇷🇪 +262',
    optionLabel: '🇷🇪 Reunion +262'
  },
  {
    value: '41',
    label: '🇨🇭 +41',
    optionLabel: '🇨🇭 Switzerland +41'
  }
] as const

export const PhoneInput = <T extends FieldValues>({
  control,
  error,
  label,
  required = false,
  name,
  setValue
}: PhoneInputProps<T>) => {
  const countryCode = useWatch({
    control,
    name: `${name}.countryCode` as Path<T>
  })

  const selectedCountry = useMemo(
    () => COUNTRY_OPTIONS.find(option => option.value === countryCode),
    [countryCode]
  )

  useEffect(() => {
    if (!countryCode) {
      setValue(
        `${name}.countryCode` as Path<T>,
        getDefaultCountryCode(i18n.language) as PathValue<T, Path<T>>
      )
    }
  }, [setValue, name, countryCode])

  return (
    <Flex className='w-full'>
      <InputWrap
        label={label}
        required={required}
        errorMessage={error?.message}
        field={
          <>
            <Controller.Select
              className='float-left w-28 [&_div]:rounded-r-none [&_div]:border-r-0'
              control={control}
              name={`${name}.countryCode` as Path<T>}
              required={required}
              defaultValue={selectedCountry?.value as PathValue<T, Path<T>>}
              options={COUNTRY_OPTIONS}
              searchValue={selectedCountry?.label}
            />
            <Controller.TextInput
              autoComplete='tel-national'
              error={Boolean(error)}
              name={`${name}.number` as Path<T>}
              className='grow [&_div]:rounded-l-none'
              required={required}
              control={control}
            />
          </>
        }
      />
    </Flex>
  )
}
