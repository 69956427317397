import type { DateRangePickerInputProps } from '@libs/ui/ds'
import { useUncontrolled } from '@libs/ui/ds'
import type { DatesRangeValue } from '@mantine/dates'
import moment from 'moment'
import { useMemo, useState } from 'react'

import { dateConfig } from './dateConfig'
import { isDateValid, useRangeErrorMessage } from './formats'

export const dateRangeConfig = {
  useSyncDate: (opts: {
    value: DateRangePickerInputProps['value']
    defaultValue: DateRangePickerInputProps['defaultValue']
    onBlur: DateRangePickerInputProps['onBlur']
    onChange: DateRangePickerInputProps['onChange']
    minDate: DateRangePickerInputProps['minDate']
    maxDate: DateRangePickerInputProps['maxDate']
    defaultDate: DateRangePickerInputProps['defaultDate']
  }) => {
    const [isDirty, setIsDirty] = useState(false)
    const [controlledValue, setControlledValue] = useUncontrolled(opts)

    const [controlledDate, setControlledDate] = useUncontrolled({
      defaultValue: opts.defaultDate || controlledValue?.start
    })

    const handleSetControlledValue = (val: {
      start?: string
      end?: string
    }) => {
      if (!isDirty) {
        setIsDirty(true)
      }
      setControlledValue({ ...controlledValue, ...val })
    }

    const handleChangeStart = (newStart: string) => {
      handleSetControlledValue({
        start: newStart,
        end: controlledValue?.end
      })

      if (isDateValid(newStart)) {
        setControlledDate(newStart)
      }
    }

    const handleChangeEnd = (newEnd: string) => {
      handleSetControlledValue({
        start: controlledValue?.start,
        end: newEnd
      })

      if (isDateValid(newEnd)) {
        const oneMonthSubstracted = moment(newEnd).add(-1, 'month')
        setControlledDate(dateConfig.toStr(oneMonthSubstracted))
      }
    }

    const memoValue = useMemo(() => {
      return [
        dateConfig.toDate(controlledValue?.start),
        dateConfig.toDate(controlledValue?.end)
      ] as DatesRangeValue
    }, [controlledValue?.start, controlledValue?.end])

    return {
      value: memoValue,
      onChange: (args: DatesRangeValue) => {
        const [start, end] = args
        handleSetControlledValue({
          start: dateConfig.toStr(start),
          end: dateConfig.toStr(end)
        })
      },
      onChangeStart: handleChangeStart,
      onChangeEnd: handleChangeEnd,
      strValue: controlledValue,

      /**
       * Calendar props
       */
      date: dateConfig.toDate(controlledDate),
      onDateChange: (val: Date) => setControlledDate(dateConfig.toStr(val)),
      resetStart: () => handleSetControlledValue({ start: '' }),
      resetEnd: () => handleSetControlledValue({ end: '' }),
      minDate: dateConfig.toDate(opts.minDate),
      maxDate: dateConfig.toDate(opts.maxDate),
      defaultDate: dateConfig.toDate(opts.defaultDate),
      errorMessage: useRangeErrorMessage({
        value: controlledValue,
        isDirty,
        minDate: opts.minDate,
        maxDate: opts.maxDate
      })
    }
  }
}
